<template>
  <CommonComp>
    <div class="cr-mail-main" v-resize="resize">
      <WriteToolbar v-if="isWrite && !showSendResult" />
      <div class="cr-mail-main-content">
        <MailWrite v-if="isWrite" />
        <MailView v-else />
      </div>
    </div>
  </CommonComp>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CommonComp from "./CommonComp.vue";
import MailView from "./components/main/MailView";
import MailWrite from "./components/main/MailWrite";
import WriteToolbar from "./components/main/toolbar/WriteToolbar";

export default {
  components: { CommonComp, MailView, MailWrite, WriteToolbar },
  created() {
    this.SET_SHOW_SPLASH(false);
    this.SET_MAIN_WIDTH(window.innerWidth);
    HTMLCollection.prototype.forEach = Array.prototype.forEach;
  },
  computed: {
    ...mapGetters("mailCompose", ["showSendResult"]),
    isWrite() {
      return this.$route.name == "popup_mail_write";
    }
  },
  methods: {
    ...mapMutations("splash", ["SET_SHOW_SPLASH"]),
    ...mapMutations("mailLayout", ["SET_MAIN_WIDTH"]),
    resize() {
      this.SET_MAIN_WIDTH(window.innerWidth);
    }
  }
};
</script>
